<div class="container">
  <mat-card appearance="outlined">
    <mat-grid-list cols="2" rowHeight="100px">
      <mat-grid-tile [colspan]="2" [rowspan]="2">
        <mat-table id="test-info" [dataSource]="candidateData" class="info-table">
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef>
              {{ 'global.userID' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.id.substr(0, 4)}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="endtime">
            <mat-header-cell *matHeaderCellDef>
              {{ 'grader.handedIn' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.endtime}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="deadline">
            <mat-header-cell *matHeaderCellDef>
              {{ 'global.deadlineForGrading' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.deadline}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>
              {{ 'global.status' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.status | translate}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="endReason">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.endReason | translate}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="location">
            <mat-header-cell *matHeaderCellDef>
              {{ 'global.location' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.location}}</mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumnsForCandidate"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsForCandidate;"></mat-row>
        </mat-table>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="4">
        <mat-table [dataSource]="statusTable" class="status-table">
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>
              {{ 'global.status' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.status | translate}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="changed">
            <mat-header-cell *matHeaderCellDef>
              {{ 'global.date' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.changed}}</mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div *ngIf="path; else noFile">
          <a href="{{path}}" id="download-link" class="nounderline">
            <button mat-button class="button-style">
              {{ 'grader.downloadZipFile' | translate }}
            </button>
          </a>
        </div>
        <ng-template #noFile>
          <button disabled="disabled">Fil saknas</button>
        </ng-template>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="2">
        <form class="outline-form">
          <mat-form-field class="form-outline" appearance="outline">
            <textarea id="comment-area" matInput [formControl]="commentValue" rows="6"
              placeholder="{{ 'grader.writeAComment' | translate }}" maxlength="350" name="Input"></textarea>
          </mat-form-field>
        </form>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <button id="submit-comment" (click)="submitComment()" [disabled]="processDone">
          {{ 'grader.submitComment' | translate }}
        </button>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card>
  <mat-card appearance="outlined">
    <div class="center">
      <button id="see-comments-button" (click)="getComments()">
        {{ 'grader.viewComments' | translate }}
      </button>
    </div>
    <mat-list id="comments-list">
      <mat-list-item *ngFor="let comment of comments">
        <div class="comment-content">
          <span class="comment-text">{{comment.comment}}</span>
          <span class="grader-name">{{comment.grader}}</span>
        </div>
        <button *ngIf="isMyComment(comment)" class="remove-button"
          (click)="removeComment(comment)">X</button>
        <mat-divider></mat-divider>
      </mat-list-item>
      <div
        *ngIf="status && (status.name==='EXAMDISTRIBUTED' || status.name==='GRADED') && this.comments && this.comments.length"
        class="finished-grading">
        <mat-checkbox class="check-if-done" [(ngModel)]="processDone" (change)="toggleDoneStatus()">
          {{ 'grader.finishedGrading' | translate }}
        </mat-checkbox>
      </div>
    </mat-list>
  </mat-card>
</div>
