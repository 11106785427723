import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  screenLargeEnough: boolean;
  shouldDisplayTitle: boolean;

  constructor(
    private cookieService: CookieService,
    translate: TranslateService,
  ) {
    let lang = this.cookieService.get('lang');
    if (lang !== 'no' && lang !== 'sv') {
      lang = window.location.hostname.includes('.no') ? 'no' : 'sv';
      this.cookieService.set('lang', lang, null, '/');
    }
    translate.setDefaultLang(lang);
    translate.use(lang);

    this.checkScreenSize();
    window.onresize = (event) => this.checkScreenSize();
  }

  checkScreenSize() {
    this.screenLargeEnough = window.innerWidth >= 700;
    this.shouldDisplayTitle = window.innerWidth >= 1000;
  }
}
