import { Component, OnInit, ViewChild, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';

export interface TableEntry {
  id: any;
  name: string;
  email: string;
  location: string;
  status: string;
}

@Component({
  selector: 'app-candidates-table',
  templateUrl: './candidates-table.component.html',
  styleUrls: ['./candidates-table.component.scss']
})
export class CandidatesTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;

  @Input() tableId: string;
  @Input() title: string;

  @Output() deleteCandidate: EventEmitter<string> = new EventEmitter();
  @Output() deleteCandidates: EventEmitter<string[]> = new EventEmitter();

  dataSource: MatTableDataSource<TableEntry> = new MatTableDataSource();
  displayedColumns: string[];
  userData: TableEntry[];
  selection = new SelectionModel<TableEntry>(true, []);
  editing = false;

  @Input()
  set tableData(source: TableEntry[]) {
    this.dataSource.data = source;
  }

  @Input()
  set filter(filter: string) {
    this.dataSource.filter = filter;
  }

  ngOnInit(): void {
    this.updateDisplayedColumns();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  toggleEditMode(): void {
    this.editing = !this.editing;
    this.updateDisplayedColumns();
  }

  updateDisplayedColumns(): void {
    if (this.editing) {
      this.displayedColumns = ['select', 'id', 'name', 'email', 'location', 'status', 'created'];
    } else {
      this.displayedColumns = ['id', 'name', 'email', 'location', 'status', 'created', 'remove'];
    }
  }

  areAllCandidatesSelected(): boolean {
    const numSelected = this.selection.selected.length;
    return numSelected === this.dataSource.data.length;
  }

  toggleAllCheckboxes(): void {
    if (this.areAllCandidatesSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.data.forEach(element => this.selection.select(element));
    }
  }

  removeCandidate(id: string): void {
    this.deleteCandidate.emit(id);
  }

  deleteSelectedCandidates(): void {
    const ids = this.selection.selected.map(element => element.id);
    if (ids.length > 0) {
      this.deleteCandidates.emit(ids);
      this.toggleEditMode();
      this.selection.clear();
    }
  }
}
