<h2 mat-dialog-title >{{ data.titleLangKey | translate:data.params }} </h2>
<mat-dialog-content class="dialog-body">
  <p>{{ data.bodyLangKey | translate:data.params }}</p>
  <mat-dialog-actions class="button-container">
    <button *ngIf="data.cancelLangKey" (click)="onCancel()">
      {{ data.cancelLangKey | translate:data.params }}
    </button>
    <button id="delete-button" (click)="onConfirm()" cdkFocusInitial>
      {{ data.confirmLangKey | translate:data.params }}
    </button>
  </mat-dialog-actions>
</mat-dialog-content>
