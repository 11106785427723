import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Status, findStatus } from '../_services/user-data.service';
import { CookieService } from 'ngx-cookie-service';
import { CandidateService } from '../_services/candidate.service';
import { TextService } from '../_services/text.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../_services/notification.service';
import { DestroyComponent } from '../util/destroy.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-candidate',
  templateUrl: './candidate.component.html',
  styleUrls: ['./candidate.component.scss'],
  providers: [CookieService]
})

export class CandidateComponent extends DestroyComponent implements OnInit {
  name: string;
  email: string;
  status: Status = { name: '', niceName: '' };
  file?: string;

  content: string;

  private sub: any;

  private couldNotLogInText: string;
  private somethingWentWrongText: string;
  private tryUsingLinkAgainText: string;

  private endReason: string = null;

  constructor(private route: ActivatedRoute,
    private candidateService: CandidateService,
    private textService: TextService,
    private cookieService: CookieService,
    private translate: TranslateService,
    private router: Router,
    private notificationService: NotificationService) {
    super();
    this.streamTranslations();
  }

  ngOnInit() {
    this.sub = this.route.params.pipe(takeUntil(this.destroy)).subscribe(params => {
      if (params.id) {
        this.createToken(params.id);
      } else if (this.cookieService.check('candidate_token')) {
        this.getCandidateData();
      } else {
        this.showErrorMessage();
      }
    });
  }

  streamTranslations() {
    this.translate.stream([
      'candidate.couldNotLogIn',
      'candidate.exam.somethingWentWrong',
      'candidate.exam.tryUsingLinkAgain',
    ]).pipe(takeUntil(this.destroy)).subscribe(data => {
      this.couldNotLogInText = data['candidate.couldNotLogIn'];
      this.somethingWentWrongText = data['candidate.exam.somethingWentWrong'];
      this.tryUsingLinkAgainText = data['candidate.exam.tryUsingLinkAgain'];
    });
  }

  startExam() {
    if (this.status && this.status.name === 'HASVISITED') {
      this.candidateService.startExam().subscribe(
        data => {
          this.status = findStatus('DOINGEXAM');
          this.router.navigateByUrl('questions');
        },
        this.handleError.bind(this)
      );
    } else {
      this.router.navigateByUrl('questions');
    }
  }

  textToLoad(): string {
    let textToLoad = 'done.html';
    if (this.status.name === 'HASVISITED' || this.status.name === 'DOINGEXAM') {
      textToLoad = 'welcome.html';
    } else {
      if (this.endReason === 'TIMEOUT') {
        textToLoad = 'timeout.html';
      } else if (this.endReason === 'LINK_OUTDATED') {
        textToLoad = 'outdated_link.html';
      }
    }
    return textToLoad;
  }

  handleError(err) {
    console.error('Fail: ', err);
    this.showErrorMessage();
  }

  showErrorMessage() {
    this.notificationService.error(this.couldNotLogInText);
    this.content = '<h1>' + this.somethingWentWrongText + '</h1>' +
      this.tryUsingLinkAgainText;
  }

  loadContent() {
    this.textService.fetchText(
      this.textToLoad(), this.translate.currentLang
    ).subscribe(
      text => {
        this.content = text;
      }
    );
  }

  getCandidateData() {
    this.candidateService.profile().subscribe(
      data => {
        this.name = data.name;
        this.email = data.email;
        this.status = findStatus(data.status);
        this.file = data.file;
        this.endReason = data.endReason;

        this.loadContent();

        this.translate.onLangChange.pipe(takeUntil(this.destroy)).subscribe(() => {
          this.loadContent();
        });
      },
      this.handleError.bind(this)
    );
  }

  createToken(id: string) {
    this.candidateService.candidateLogin(id).subscribe(
      data => {
        // Created new token
        this.getCandidateData();
      },
      this.handleError.bind(this)
    );
  }
}
