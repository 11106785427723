<mat-sidenav-container>
  <mat-sidenav mode=side opened #sidebar>
    <mat-nav-list>
      <a mat-list-item routerLink=".">
        {{ 'grader.seeAllTests' | translate }}
      </a>
      <a mat-list-item href="https://docs.google.com/document/d/1UHASKmTY-MWikMXUcdNB8r7ykq4jQcOPrifaE532P0A"
        target="_blank">
        {{ 'global.help' | translate }}
      </a>
      <a mat-list-item routerLink="/logout">
        {{ 'global.logOut' | translate }}
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div (click)="sidebar.toggle()" class="toggle-container">
      <span class="sidebar-toggle material-icons" [class.sidebar-closed]="!sidebar.opened">chevron_left</span>
    </div>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
