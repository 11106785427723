<link href='https://fonts.googleapis.com/css?family=Lato:400,700'
      rel='stylesheet'
      type='text/css'>
<div *ngIf="screenLargeEnough; then bigScreen else smallScreen">
</div>

<ng-template #bigScreen>
  <div fxLayout="column">
    <mat-toolbar class="header" color="primary">
      <a href="/"><img src="../assets/aspire-logo.svg" class="header-img"></a>
      <a *ngIf="shouldDisplayTitle" href="/" class="header-text">
        {{ 'global.programmingTest' | translate }}
      </a>
    </mat-toolbar>
    <router-outlet class="router-flex"></router-outlet>
  </div>
</ng-template>

<ng-template #smallScreen>
  <mat-toolbar class="header-style">
    <mat-toolbar-row>
      <span>
        <a href="/"><img src="../assets/aspire-logo.svg"
               class="header-img"></a>
      </span>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="small-message">
    <h1>{{ 'global.screenTooSmall' | translate }}</h1>
    {{ 'global.screenTooSmallBody' | translate }}
  </div>
</ng-template>
