<div class="top-container">
  <h2>
    {{ 'admin.changeTexts' | translate }}
  </h2>

  <mat-form-field>
    <mat-select [value]="selectedText" (selectionChange)="onTextSelect($event)" name="textSelect">
      <mat-option *ngFor="let text of texts" [value]="text">
        {{text.title | translate}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="text-container">
  <div class="edit-container">
    <h3 class="preview-title">{{ selectedText.description | translate }}</h3>

    <p *ngIf="isEmailText(selectedText)">
      {{ selectedText.templateDescription | translate }}
    </p>

    <textarea matInput [name]="selectedText.name" (input)="onTextInput(selectedText, input.value)" #input
      [(ngModel)]="content">
    </textarea>

    <div class="button-container">
      <div *ngIf="isEmailText(selectedText) && !emailOk" class="error-message">
        {{ 'admin.texts.errors.textIsMalformed' | translate}}: {{ emailError | translate }}.
      </div>

      <!-- Button should only be enabled if there are unsaved changes and the email texts are OK -->
      <button class="save-button" [disabled]="!unsavedChanges || !emailOk" (click)="save(selectedText, input.value)">
        {{ 'admin.texts.save' | translate }}
      </button>
    </div>

  </div>

  <div class="preview-container">
    <h3 class="preview-title">
      {{ 'admin.texts.preview' | translate }}
    </h3>

    <!-- <div class="divider"></div> -->

    <div *ngIf="selectedText.file.endsWith('.html')" class="html-showspace" [innerHTML]="content"></div>

    <div *ngIf="isEmailText(selectedText)" class="email-showspace" [innerHTML]="preview"></div>
  </div>
</div>
