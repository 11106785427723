<div class="header">
  <h3>{{title}}</h3>
  <ng-container *ngIf="editing; then edit; else noEdit"></ng-container>

  <ng-template #noEdit>
    <button mat-raised-button (click)="toggleEditMode()">
      {{ 'admin.candidatesComponent.deleteMultiple' | translate }}
    </button>
  </ng-template>

  <ng-template #edit>
    <div>
      <button mat-raised-button #edit class="done-button" (click)="toggleEditMode()">
        {{ 'admin.candidatesComponent.cancel' | translate }}
      </button>
      <button mat-raised-button #edit class="delete-button" color="warn" (click)="deleteSelectedCandidates()">
        {{ 'admin.candidatesComponent.delete' | translate }}
      </button>
    </div>
  </ng-template>
</div>

<table mat-table [dataSource]="dataSource" matSort [id]="tableId">

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? toggleAllCheckboxes() : null"
        [checked]="selection.hasValue() && areAllCandidatesSelected()" id="toggle-all-checkboxes"
        [indeterminate]="selection.hasValue() && !areAllCandidatesSelected()">
      </mat-checkbox>
    </th>
    <td mat-cell class="narrow-cell" *matCellDef="let element">
      <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(element) : null"
        [checked]="selection.isSelected(element)"></mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'global.userID' | translate }}
    </th>
    <td mat-cell class="narrow-cell" *matCellDef="let element" routerLink="/admin/candidate/{{element.id}}">
      {{element.id.substr(0,4)}} </td>
  </ng-container>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'global.name' | translate }}
    </th>
    <td mat-cell class="table-cell" *matCellDef="let element" routerLink="/admin/candidate/{{element.id}}">
      {{element.name}} </td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'global.email' | translate }}
    </th>
    <td mat-cell class="table-cell" *matCellDef="let element" routerLink="/admin/candidate/{{element.id}}">
      {{element.email}} </td>
  </ng-container>

  <ng-container matColumnDef="location">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'global.location' | translate }}
    </th>
    <td mat-cell class="narrow-cell" *matCellDef="let element" routerLink="/admin/candidate/{{element.id}}">
      {{element.location}} </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'global.status' | translate }}
    </th>
    <td mat-cell class="table-cell" *matCellDef="let element" routerLink="/admin/candidate/{{element.id}}">{{
      element.status | translate }} </td>
  </ng-container>

  <ng-container matColumnDef="created">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'global.statusName.created' | translate }}
    </th>
    <td mat-cell class="table-cell" *matCellDef="let element" routerLink="/admin/candidate/{{element.id}}">{{
      element.created | translate }} </td>
  </ng-container>

  <ng-container matColumnDef="remove">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'admin.candidatesComponent.delete' | translate }}
    </th>
    <td mat-cell class="narrow-cell" *matCellDef="let element">
      <button mat-raised-button (click)="removeCandidate(element.id)">X</button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let myRowData; let element; columns: displayedColumns;"></tr>
</table>

<div *ngIf="dataSource.filteredData.length === 0" class="no-data">
  —
</div>
