<div *ngIf="currentCandidatesDataSource.data.length > 0; else noCurrentCandidates">
  <h3>{{ 'grader.candidatesToGrade' | translate }}</h3>
  <mat-table id="current-candidates-table" [dataSource]="currentCandidatesDataSource">
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef>
        {{ 'global.userID' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.id.substr(0,4)}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="endtime">
      <mat-header-cell *matHeaderCellDef>
        {{ 'grader.handedIn' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.endtime}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="deadline">
      <mat-header-cell *matHeaderCellDef>
        {{ 'global.deadlineForGrading' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.deadline}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>
        {{ 'global.status' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.status | translate}}</mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="getTestDetails(row)"></mat-row>
  </mat-table>
</div>
<ng-template #noCurrentCandidates>
  <div>
    <h2 id="no-current-candidates" class="nothing-to-show">
      {{ 'grader.noCandidatesToGrade' | translate }}
    </h2>
  </div>
</ng-template>

<div *ngIf="finishedCandidatesDataSource.data.length > 0; else noFinishedCandidates">
  <h3>{{ 'grader.gradedCandidates' | translate }}</h3>
  <mat-table id="finished-candidates-table" [dataSource]="finishedCandidatesDataSource">
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef>
        {{ 'global.userID' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.id.substr(0,4)}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="endtime">
      <mat-header-cell *matHeaderCellDef>
        {{ 'grader.handedIn' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.endtime}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="deadline">
      <mat-header-cell *matHeaderCellDef>
        {{ 'global.deadlineForGrading' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.deadline}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>
        {{ 'global.status' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.status | translate}}</mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="getTestDetails(row)"></mat-row>
  </mat-table>
</div>
<ng-template #noFinishedCandidates>
  <div>
    <h2 id="no-finished-candidates" class="nothing-to-show">
      {{ 'grader.noCandidatesToShow' | translate }}
    </h2>
  </div>
</ng-template>
