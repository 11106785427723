<mat-sidenav-container class="sidenav-container">
  <mat-sidenav mode="side" opened>
    <mat-list role="list">
      <mat-list-item role="listitem" style="height: 30%">
        {{ name }}
      </mat-list-item>
      <mat-list-item role="listitem" class="email">{{ email }}
      </mat-list-item>
      <mat-list-item role="listitem">
        Status: {{ status.niceName | translate }}
      </mat-list-item>
    </mat-list>
    <div *ngIf="this.file">
      <mat-list role="list">
        <mat-list-item role="listitem">
          {{ 'global.file' | translate }}:
          &nbsp;
          <a id="file-link" href="{{file}}">
            {{ 'global.link' | translate }}
          </a>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-sidenav>
  <mat-sidenav-content fxFlex="grow">
    <router-outlet></router-outlet>
    <div class="content-container" [innerHTML]="content"></div>
    <div *ngIf="status.name === 'HASVISITED' || status.name === 'DOINGEXAM'">
      <button id="start-test-button" (click)=startExam()>
        {{(status.name === 'HASVISITED' ? 'candidate.startExam' : 'candidate.continueExam') | translate }}
      </button>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
