<h3>{{ 'admin.candidateData.candidateInformation' | translate }}</h3>
<table mat-table [dataSource]="dataSource" class="dataTable">
  <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef> {{ 'global.userID' | translate }} </mat-header-cell>
    <mat-cell class="narrow-cell" *matCellDef="let element">{{element.id.substr(0,4)}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>{{ 'global.name' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.name}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef>{{ 'global.email' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.email}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="location">
    <mat-header-cell *matHeaderCellDef>{{ 'global.location' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.location}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef>{{ 'global.status' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.status | translate }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="endReason">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.endReason | translate }}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="candidateColumns"></mat-header-row>
  <mat-row *matRowDef="let myRowData; columns: candidateColumns"></mat-row>
</table>

<h3>{{ 'admin.candidateData.examInformation' | translate }}</h3>
<mat-card appearance="outlined" id="exam-info">
  <table mat-table [dataSource]="dataSource" class="dataTable">
    <ng-container matColumnDef="startTime">
      <mat-header-cell *matHeaderCellDef>{{ 'admin.candidateData.startTime' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.startTime}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="endTime">
      <mat-header-cell *matHeaderCellDef>{{ 'admin.candidateData.endTime' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.endTime}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="examTime">
      <mat-header-cell *matHeaderCellDef> {{ 'admin.candidateData.examTime' | translate }} </mat-header-cell>
      <mat-cell id="exam-time" *matCellDef="let element">{{hours}}:{{minutes}}:{{seconds}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="files">
      <mat-header-cell *matHeaderCellDef>{{ 'global.file' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div *ngIf="file; else noFile">
          <a id="file-link" href="{{file}}" target="_self" download="{{filename}}">
            {{ 'global.file' | translate }}
          </a>
        </div>
        <ng-template #noFile>
          <input type="file" id="file" name="file" (change)="onFileSelect($event)" class="hiddeninput"
            accept="{{createAcceptedExtensionsForUpload()}}" />
          <label for="file">
            <div class="fake-button">
              {{'candidate.exam.selectAFile' | translate}}
            </div>
          </label>

        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="graderGroup">
      <mat-header-cell *matHeaderCellDef>{{ 'admin.candidateData.assignedGraderGroup' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.graderGroup}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="gradingDeadline">
      <mat-header-cell *matHeaderCellDef> {{ 'global.deadlineForGrading' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.gradingDeadline}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="examColumns"></mat-header-row>
    <mat-row *matRowDef="let myRowData; columns: examColumns"></mat-row>
  </table>

  <mat-checkbox class="check-if-done" [(ngModel)]="processDone" (change)="toggleDoneStatus()">
    {{ 'admin.candidateData.processDone' | translate }}
  </mat-checkbox>
</mat-card>

<div class="assign-container" *ngIf="this.status.name === 'EXAMDONE' || this.status.name === 'EXAMDISTRIBUTED'">
  <div>
    <mat-label>
      {{ 'admin.candidateData.assignToGraderGroup' | translate }}:
    </mat-label>
    <mat-form-field>
      <mat-select id="grader-location-select" [(ngModel)]="selectedGraderCity"
        placeholder="{{ 'global.location' | translate }}" class="dropdown-city" required>
        <mat-option *ngFor="let city of cities" [value]="city.name">{{city.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-label>
      {{ 'admin.candidateData.setDeadline' | translate }}:
    </mat-label>
    <mat-form-field>
      <input id="grader-deadline-datepicker" matInput [matDatepicker]="myDatepicker" [(ngModel)]="deadline"
        [min]="today">
      <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
      <mat-datepicker #myDatepicker></mat-datepicker>
    </mat-form-field>
  </div>
  <button (click)="selectGraderGroup()">
    <ng-container *ngIf="!alreadyAssigned">{{ 'admin.candidateData.assign' | translate }}</ng-container>
    <ng-container *ngIf="alreadyAssigned">{{ 'admin.candidateData.reassign' | translate }}</ng-container>
  </button>
</div>

<mat-card appearance="outlined" id="remove-candidate">
      <label for="candlink">{{ 'admin.candidateData.candidateLink' | translate }}: </label>
      <input name="candlink" type="text" #candLink [value]="link" style="width: 30vw" />
      <button mat-icon-button (cbOnSuccess)="onClipboardCopy(true)" (cbOnError)="onClipboardCopy(false)"
        [ngxClipboard]="candLink">
        <mat-icon>content_copy</mat-icon>
      </button>
      <button (click)="deleteCandidate()" class="button-style delete-candidate-button">
        {{ 'admin.candidateData.deleteCandidate' | translate }}
      </button>
</mat-card>

<mat-card appearance="outlined">
  <mat-grid-list cols="2" rowHeight="100px">
    <mat-grid-tile [colspan]="1" [rowspan]="1">
      <mat-toolbar>
        {{ 'admin.candidateData.statusHistory' | translate }}
      </mat-toolbar>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1" [rowspan]="1">
      <mat-toolbar>
        {{ 'admin.candidateData.comments' | translate }}
      </mat-toolbar>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1" [rowspan]="4">
      <table mat-table id="status-history-table" [dataSource]="dataSourceHistory" class="history-table">
        <ng-container matColumnDef="changed">
          <mat-header-cell id="date-sort-header" *matHeaderCellDef>
            {{ 'global.date' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.changed}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="statusHistory">
          <mat-header-cell *matHeaderCellDef>
            {{ 'global.status' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.status | translate }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="changedBy">
          <mat-header-cell *matHeaderCellDef>
            {{ 'global.changedBy' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.changedBy}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsHistory"></mat-header-row>
        <mat-row *matRowDef="let myRowData; columns: displayedColumnsHistory"></mat-row>
      </table>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1" [rowspan]="4" class="list-tile">
      <mat-list class="comment-list">
        <mat-list-item class="comment-item" *ngFor="let comment of comments">
          <p mat-line>
            <span class="comment-text">{{comment.comment}}</span>
          </p>
          <p mat-line>
            <span class="grader-name">{{comment.grader}}</span>
          </p>
          <mat-divider></mat-divider>
        </mat-list-item>
      </mat-list>
    </mat-grid-tile>
  </mat-grid-list>
</mat-card>
