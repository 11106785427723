type City = {
  name: 'Stockholm' | 'Linköping' | 'Örebro' | 'Borlänge';
};

const cities: Array<City> = [
  { name: 'Stockholm' },
  { name: 'Linköping' },
  { name: 'Örebro' },
  { name: 'Borlänge' }
];

export { City, cities };
