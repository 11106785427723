import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserDataService, STATUS_NAME_MAP } from '../../_services/user-data.service';
import { LoginService } from '../../_services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../../_services/dialog.service';
import { take, takeUntil } from 'rxjs/operators';
import { NotificationService } from '../../_services/notification.service';
import { TableEntry } from './candidates-table.component';
import { DestroyComponent } from '../../util/destroy.component';

@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  styleUrls: ['./candidates.component.scss']
})
export class CandidatesComponent extends DestroyComponent implements OnInit {
  allCandidates: TableEntry[];
  currentCandidates: TableEntry[] = [];
  finishedCandidates: TableEntry[] = [];
  shouldDisplayCandidates = true;
  filter = '';

  private couldNotRemoveCandidatesText: string;
  private couldNotRemoveCandidateText: string;

  constructor(public router: Router,
    private userDataService: UserDataService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private notificationService: NotificationService) {
    super();
    this.streamTranslations();
  }

  ngOnInit() {
    this.refresh();
  }

  streamTranslations() {
    this.translate.stream([
      'admin.candidatesComponent.messages.couldNotRemoveCandidates',
      'admin.candidatesComponent.messages.couldNotRemoveCandidate',
    ]).pipe(takeUntil(this.destroy)).subscribe(
      data => {
        this.couldNotRemoveCandidatesText = data['admin.candidatesComponent.messages.couldNotRemoveCandidates'];
        this.couldNotRemoveCandidateText = data['admin.candidatesComponent.messages.couldNotRemoveCandidate'];
      }
    );
  }

  applyFilter(filterValue: string) {
    this.filter = filterValue.trim().toLowerCase();
  }

  getCandidateData() {
    this.userDataService.candidateData().subscribe(
      data => {
        this.allCandidates = data;
        for (const i of Object.keys(data)) {
          data[i].status = STATUS_NAME_MAP[data[i].status];
        }
        this.currentCandidates = data.filter(c => c.status !== 'global.statusName.done');
        this.finishedCandidates = data.filter(c => c.status === 'global.statusName.done');
        this.shouldDisplayCandidates = data.length > 0;
      },
      err => { console.error('Failed getting candidate data: ', err); }
    );
  }

  refresh() {
    this.getCandidateData();
  }

  onDeleteCandidate(id: string) {
    this.openDeleteCandidateDialog(id).afterClosed().pipe(take(1)).subscribe(confirm => {
      if (confirm) {
        this.userDataService.deleteCandidate(id).subscribe(
          data => {
            this.refresh();
          },
          err => {
            console.error(err);
            this.notificationService.error(this.couldNotRemoveCandidateText);
          }
        );
      }
    });
  }

  onDeleteCandidates(ids: string[]) {
    this.openDeleteCandidatesDialog(ids).afterClosed().pipe(take(1)).subscribe(confirm => {
      if (confirm) {
        this.userDataService.deleteMultipleCandidates(ids).subscribe(
          data => {
            this.refresh();
          },
          error => {
            this.notificationService.error(this.couldNotRemoveCandidatesText);
          }
        );
      }
    });
  }

  private openDeleteCandidateDialog(id: string): MatDialogRef<any, boolean> {
    return this.dialogService.confirm({
      titleLangKey: 'admin.candidatesComponent.confirmRemoveDialog.single.title',
      bodyLangKey: 'admin.candidatesComponent.confirmRemoveDialog.single.body',
      confirmLangKey: 'admin.candidatesComponent.confirmRemoveDialog.single.confirm',
      cancelLangKey: 'admin.candidatesComponent.confirmRemoveDialog.single.cancel',
      params: { candidate: this.getCandidateName(id) }
    });
  }

  private openDeleteCandidatesDialog(ids: string[]): MatDialogRef<any, boolean> {
    return this.dialogService.confirm({
      titleLangKey: 'admin.candidatesComponent.confirmRemoveDialog.multiple.title',
      bodyLangKey: 'admin.candidatesComponent.confirmRemoveDialog.multiple.body',
      confirmLangKey: 'admin.candidatesComponent.confirmRemoveDialog.multiple.confirm',
      cancelLangKey: 'admin.candidatesComponent.confirmRemoveDialog.multiple.cancel',
      params: { candidates: this.getCandidateNames(ids) }
    });
  }

  private getCandidateName(id: string): string {
    return `"${this.allCandidates.find(u => u.id === id).name}"`;
  }

  private getCandidateNames(ids: string[]): string {
    return this.allCandidates.filter(u => ids.includes(u.id)).map(u => `"${u.name}"`).join(', ');
  }

}
