import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MsalGuardConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE } from '@azure/msal-angular';
import {
  IPublicClientApplication,
  AzureCloudInstance,
  AzureCloudOptions,
  PublicClientApplication,
  InteractionType
} from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

function MSALInstanceFactory(): IPublicClientApplication {
  const { azTenant: tenant, azClient: clientId } = environment;

  const azureCloudInstance: AzureCloudInstance = 'https://login.microsoftonline.com';

  const azureCloudOptions: AzureCloudOptions = {
    azureCloudInstance,
    tenant,
  };

  const instance = new PublicClientApplication({
    auth: {
      azureCloudOptions,
      redirectUri: `${location.origin}/login`,
      clientId,
    },
  });

  instance.initialize();

  return instance;
}

function MSALGuardFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Popup,
    loginFailedRoute: '/login',
  };
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MsalModule,
  ],
  exports: [
    MsalModule,
  ],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardFactory
    },
    MsalService,
  ]
})
export class AuthModule { }
