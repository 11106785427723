import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CandidateService {

  constructor(private http: HttpClient,
              private cookieService: CookieService) { }

  candidateLogin(id) {
    return this.http.post<any>('/api/candidate/login/' + id, {observe: 'response'}).pipe(
      map(res => {
        if (res.body.role) {
          return res.body;
        }
      }),
      catchError(error => of(false))
    );
  }

  profile() {
    return this.http.get<any>('/api/candidate', { });
  }

  startExam() {
    return this.http.post<any>('/api/candidate/startExam', {});
  }
}
