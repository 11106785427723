import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { STATUS_NAME_MAP, UserDataService } from '../../_services/user-data.service';
import { LoginService } from '../../_services/login.service';

@Component({
  selector: 'app-exam-list',
  templateUrl: './exam-list.component.html',
  styleUrls: ['./exam-list.component.scss']
})
export class ExamListComponent implements OnInit {

  displayedColumns: string[] = ['id', 'endtime', 'deadline', 'status' ];
  currentCandidatesDataSource = new MatTableDataSource();
  finishedCandidatesDataSource = new MatTableDataSource();

  constructor(private userDataService: UserDataService,
              public router: Router,
              private loginService: LoginService) { }

  ngOnInit() {
    this.getTestInfo();
  }

  getTestInfo() {
    this.userDataService.candidateData().subscribe(
      data => {
        const currentCandidates = [];
        const finishedCandidates = [];
        for (const el of data) {
          const status = el.status;
          const dataObject = {
            id: el.id,
            endtime: el.endtime,
            deadline: el.deadline,
            status: STATUS_NAME_MAP[status]
          };
          if (status === 'EXAMDISTRIBUTED') {
            currentCandidates.push(dataObject);
          } else {
            finishedCandidates.push(dataObject);
          }
        }
        this.currentCandidatesDataSource.data = currentCandidates;
        this.finishedCandidatesDataSource.data = finishedCandidates;
      },
      err => {
        console.error('Failed to get testinfo ', err);
      }
    );
  }

  getTestDetails(row) {
    this.router.navigateByUrl('grader/exam/' + row.id);
  }

}
