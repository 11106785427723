import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class UploadService {
  constructor(private http: HttpClient) { }

  upload(formData: FormData): Observable<HttpEvent<any>> {
    return this.http.post('/api/upload', formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  uploadAsAdmin(formData: FormData, id: string) {
    return this.http.post('/api/candidates/' + id + '/upload', formData);
  }

  fetchQuestions(lang: string): Observable<string> {
    return this.http.get<string>('/api/texts/questions.html?lang=' + lang, {});
  }

  getSupportedExtensions(): Observable<string[]> {
    return this.http.get<string[]>('/api/extensions', {});
  }
}
