import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';

@Component({
  selector: 'app-grader',
  templateUrl: './grader.component.html',
  styleUrls: ['./grader.component.scss']
})

@Injectable({
  providedIn: 'root',
})

export class GraderComponent {
  constructor() { }
}
