<div id="candidate">
  <h3>{{ 'login.candidates' | translate }}</h3>
  <p>{{ 'login.useLinkInEmail' | translate }}</p>
</div>

<mat-divider></mat-divider>

<div id="employee">
  <h3>{{ 'login.employees' | translate }}</h3>
  <button id="login-button" (click)="onClickLogin()">
    {{ 'login.logIn' | translate }}
  </button>
</div>
