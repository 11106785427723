import { Component, OnInit } from '@angular/core';
import { UserDataService } from '../../_services/user-data.service';
import { LoginService } from '../../_services/login.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, ValidationErrors } from '@angular/forms';
import { NotificationService } from '../../_services/notification.service';
import { takeUntil } from 'rxjs/operators';
import { DestroyComponent } from '../../util/destroy.component';
import { cities } from 'src/app/common/city';

export interface Role {
  value: string;
  name: string;
}

const errorMessageKeys = [
  'admin.addUser.errorMessages.badAuth',
  'admin.addUser.errorMessages.incompleteForm',
  'admin.addUser.errorMessages.notAspire',
  'admin.addUser.errorMessages.emailTooLong',
  'admin.addUser.errorMessages.nameTooLong',
  'admin.addUser.errorMessages.unknownDatabaseError',
  'admin.addUser.errorMessages.emailTaken',
  'admin.addUser.errorMessages.invalidEmail',
  'admin.addUser.errorMessages.invalidLocation',
  'admin.addUser.errorMessages.invalidRole'
];

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})

export class AddUserComponent extends DestroyComponent {
  errorMessages = {};
  formGroup: UntypedFormGroup;

  couldNotAddUserText: string;
  unknownError: string;

  roles: Role[] = [];

  submitEnabled = true;

  cities = cities;

  constructor(private userDataService: UserDataService,
    private translate: TranslateService,
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService) {
    super();
    this.formGroup = this.formBuilder.group({
      userEmail: new UntypedFormControl('', [Validators.required, Validators.email]),
      userName: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
      userRole: new UntypedFormControl('', [Validators.required]),
      selectedCity: new UntypedFormControl('', [Validators.required])
    });
    this.formGroup.setValidators([this.attentecOnlyValidator]);
    this.streamTranslations();
  }

  streamTranslations() {
    this.translate.stream([
      'global.unknownErrorOccurred',
      'admin.addUser.couldNotAddUserText',
      'global.candidate',
      'global.admin',
      'global.grader',
      ...errorMessageKeys
    ]).pipe(takeUntil(this.destroy)).subscribe(data => {
      this.couldNotAddUserText = data['admin.addUser.couldNotAddUserText'];
      this.unknownError = data['global.unknownErrorOccurred'];
      this.roles = [
        { value: 'candidate', name: data['global.candidate'] },
        { value: 'admin', name: data['global.admin'] },
        { value: 'grader', name: data['global.grader'] }
      ];

      for (const key of errorMessageKeys) {
        const keySplit = key.split('.');
        const shortKey = keySplit[keySplit.length - 1];
        this.errorMessages[shortKey] = data[key];
      }
    });
  }

  submitUserData() {
    this.submitEnabled = false;
    const form = this.formGroup.getRawValue();
    this.userDataService
      .addUser(
        form.userEmail,
        form.userName,
        form.selectedCity,
        form.userRole
      )
      .subscribe(
        data => {
          if (form.userRole === 'candidate') {
            this.router.navigate(['/admin/candidates']);
          } else {
            this.router.navigate(['/admin/users']);
          }
        },
        (err) => {
          this.submitEnabled = true;
          console.error(err.error);
          const error = err.error.error;
          if (error in this.errorMessages) {
            this.notificationService.error(this.couldNotAddUserText + this.errorMessages[error]);
          } else {
            this.notificationService.error(this.couldNotAddUserText + this.unknownError);
          }
        }
      );
  }

  private attentecOnlyValidator(formGroup: UntypedFormGroup): ValidationErrors {
    const email = formGroup.get('userEmail');
    const role = formGroup.get('userRole');
    if (!role || !email || !role.value || !email.value) {
      return null;
    }

    const isAspire = email.value.includes('@aspire.se');

    const isValid = isAspire || role.value === 'candidate';
    return isValid ? null : { attentecOnly: true };
  }
}
