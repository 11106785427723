import { Component } from '@angular/core';
import { Injectable } from '@angular/core';

@Component({
  selector: 'app-admin-component',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
@Injectable({
  providedIn: 'root',
})
export class AdminComponent {
}
