import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(private router: Router) {}

  getRole(): string {
    return sessionStorage.getItem('userRole') || '';
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.getRole() === 'admin') {
      return true;
    }

    if (route.url.some(r => r.path === 'grader')) {
      return this.getRole() === 'grader';
    }

    return this.router.parseUrl(`/${this.getRole() || 'login'}`);
  }
}
