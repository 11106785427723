
<div *ngIf="!shouldDisplayCandidates">
  <h2>
    {{ 'admin.candidatesComponent.noCandidatesToShow' | translate }}
  </h2>
  <p>
    {{ 'admin.candidatesComponent.addCandidatesByClicking' | translate }}
    <b>{{ 'admin.addCandidatesStaff' | translate }}</b>
  </p>
</div>

<div *ngIf="shouldDisplayCandidates" id="candidates-list-div">
  <h2>{{ 'admin.candidates' | translate }}</h2>

  <mat-form-field class="filter">
    <input matInput (keyup)="applyFilter($event.target['value'])"
      [placeholder]="'admin.candidatesComponent.search' | translate" id="search-bar">
  </mat-form-field>

  <app-candidates-table [title]="'admin.currentCandidates' | translate" (deleteCandidate)="onDeleteCandidate($event)"
  (deleteCandidates)="onDeleteCandidates($event)" [tableData]="currentCandidates" [filter]="filter"
  tableId="current-candidates-table"></app-candidates-table>

  <app-candidates-table [title]="'admin.staleCandidates' | translate" (deleteCandidate)="onDeleteCandidate($event)"
    (deleteCandidates)="onDeleteCandidates($event)" [tableData]="finishedCandidates" [filter]="filter"
    tableId="stale-candidates-table"></app-candidates-table>
</div>
