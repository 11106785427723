import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const isInternetExplorer = /msie\s|trident\//i.test(window.navigator.userAgent);

if (isInternetExplorer) {
  alert('Sorry, this page is not supported by Internet Explorer.\n' +
    'Use for example Microsoft Edge, Mozilla Firefox or Google Chrome instead');
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
