<div [formGroup]="formGroup" class="container">
  <h2>{{ 'admin.addUser.addUser' | translate }}</h2>
  <div class="grid">
    <mat-form-field>
      <input matInput type=email placeholder="{{ 'global.email' | translate }}" formControlName="userEmail"
        name="userEmail">
      <mat-error id="invalid-email">
        {{ 'admin.addUser.emailInvalidMessage' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="{{ 'global.name' | translate }}" formControlName="userName" name="userName">
      <mat-error id="invalid-name">
        {{'admin.addUser.errorMessages.nameTooLong' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="small">
      <mat-select formControlName="selectedCity" name="userCity" placeholder="{{ 'global.location' | translate }}">
        <mat-option *ngFor="let city of cities" [value]="city.name">
          {{city.name}}
        </mat-option>
      </mat-select>
      <mat-error id="invalid-location">
        {{ 'admin.addUser.locationMustBeSelectedMessage' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="small">
      <mat-select formControlName="userRole" name="userRole" placeholder="{{ 'global.role' | translate }}">
        <mat-option *ngFor="let role of roles" [value]="role.value">
          {{role.name}}
        </mat-option>
      </mat-select>
      <mat-error id="invalid-role">
        {{ 'admin.addUser.roleMustBeSelectedMessage' | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <mat-error *ngIf="formGroup.errors?.attentecOnly" id="attentec-only-email">
    {{ 'admin.addUser.attentecOnlyMailMessage' | translate }}
  </mat-error>

  <button [disabled]="!formGroup.valid || !submitEnabled" (click)="submitUserData()" id="submit-button">
    {{ 'admin.addUser.add' | translate }}
  </button>
</div>
