import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class LoginService {

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  login(userToken, username) {
    return this.http.post<any>('/api/login', {token: userToken, email: username}, {observe: 'response'}).pipe(
      map(({body}) => {
        if (body.role) {
          sessionStorage.setItem('userRole', body.role);
          return body;
        }
        return false;
      },
      catchError(error => of(error.error)))
    );
  }

  logout() {
    return this.http.get<any>('/api/logout', {observe: 'response'}).pipe(
      map(({body: {logout}}) => logout === 'success'),
      catchError(error => of(false))
    );
  }

  isLoggedIn() { /* fetch user data */
    return this.http.get<any>('/api/authenticate', {observe: 'response'}).pipe(
      map(data => data.body),
    );
  }
}
