import { Injectable, Injector} from '@angular/core';
import { environment } from './../../environments/environment';

import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.startsWith('/api')) {
      const newRequest = req.clone({
        url: environment.backendUrl + req.url,
        withCredentials: true,
      });
      return next.handle(newRequest);
    } else {
      return next.handle(req);
    }
  }
}
