import { Injectable } from '@angular/core';
import { ToastrService, ActiveToast, IndividualConfig } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private baseConfig: Partial<IndividualConfig> = {
    closeButton: true,
    progressBar: true,
    positionClass: 'toast-bottom-center',
    timeOut: 5000
  };

  constructor(private toastrService: ToastrService) { }

  success(message: string, title?: string): ActiveToast<any> {
    return this.toastrService.success(message, title, this.baseConfig);
  }

  info(message: string, title?: string): ActiveToast<any> {
    return this.toastrService.info(message, title, this.baseConfig);
  }

  warning(message: string, title?: string): ActiveToast<any> {
    return this.toastrService.warning(message, title, {
      ...this.baseConfig,
      timeOut: 6000
    });
  }

  error(message: string, title?: string): ActiveToast<any> {
    return this.toastrService.error(message, title, {
      ...this.baseConfig,
      timeOut: 7000
    });
  }
}
