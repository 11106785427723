import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../_services/login.service';
import { CookieService } from 'ngx-cookie-service';
import { NotificationService } from '../_services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { DestroyComponent } from '../util/destroy.component';
import { takeUntil } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-common';

@Component({
  selector: 'app-login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [CookieService]
})

export class LoginComponent extends DestroyComponent implements OnInit {
  private couldNotLogIn: string;

  constructor(private router: Router,
    private authService: MsalService,
    private loginService: LoginService,
    private cookieService: CookieService,
    private translateService: TranslateService,
    private notificationService: NotificationService) {
    super();
    this.streamTranslations();
  }

  loginRoute(role: string) {
    this.router.navigateByUrl(`/${role}`);
  }

  ngOnInit() {
    if (this.router.url === '/logout') {
      this.onClickSignOut();
    } else {
      if (this.cookieService.check('token')) {
        this.loginService.isLoggedIn().subscribe(
          response => {
            if (response.authentication) {
              this.loginRoute(response.role);
            }
          }
        );
      }
    }
  }

  onClickLogin() {
    this.authService.loginPopup().subscribe(({ account }: AuthenticationResult) => {
      this.authService.instance.setActiveAccount(account);
      this.loginService.login(account.idToken, account.username).subscribe({
        next: (response) => {
          if (response.role) {
            this.loginRoute(response.role);
          } else {
            console.error(response);
            this.notificationService.error(`${this.couldNotLogIn}: ${response.msg}`);
            this.router.navigateByUrl('/login');
          }
        },
        error: err => {
          console.error(err);
          this.notificationService.error(`${this.couldNotLogIn}: ${err.msg}`);
          this.router.navigateByUrl('/login');
        }
      });
    });
  }

  onClickSignOut() {
    this.loginService.logout().subscribe(
      () => {
        this.authService.logoutPopup();
        this.router.navigateByUrl('/');
      },
    );
  }

  private streamTranslations(): void {
    this.translateService.stream([
      'login.couldNotLogIn'
    ]).pipe(takeUntil(this.destroy)).subscribe(translations => {
      this.couldNotLogIn = translations['login.couldNotLogIn'];
    });
  }
}
