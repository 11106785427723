import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login-component/login.component';
import { AdminComponent } from './admin-component/admin.component';
import { AddUserComponent } from './admin-component/add-user/add-user.component';
import { CandidateDataComponent } from './admin-component/candidate-data/candidate-data.component';
import { QuestionsComponent } from './questions-component/questions.component';
import { CandidateComponent } from './candidate-component/candidate.component';
import { GraderComponent } from './grader-component/grader.component';
import { ExamComponent } from './grader-component/exam-component/exam.component';
import { ExamListComponent } from './grader-component/exam-list/exam-list.component';
import { TextsComponent } from './admin-component/texts/texts.component';
import { UsersComponent } from './admin-component/users/users.component';
import { CandidatesComponent } from './admin-component/candidates/candidates.component';
import { NotFoundComponent } from './notfound-component/notfound.component';
import { MsalGuard } from '@azure/msal-angular';
import { RoleGuard } from './role.guard';
import { CanDeactivateGuard } from './can-deactivate.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LoginComponent },
  {
    path: 'admin', component: AdminComponent, canActivate: [MsalGuard, RoleGuard], children: [
      { path: '', pathMatch: 'full', redirectTo: 'candidates' },
      { path: 'candidates', component: CandidatesComponent },
      { path: 'add-user', component: AddUserComponent },
      { path: 'users', component: UsersComponent },
      { path: 'texts', component: TextsComponent, canDeactivate: [CanDeactivateGuard] },
      { path: 'candidate/:id', component: CandidateDataComponent },
    ]
  },
  {
    path: 'grader', component: GraderComponent, canActivate: [MsalGuard, RoleGuard], children: [
      { path: '', pathMatch: 'full', redirectTo: 'exam-list' },
      { path: 'exam-list', component: ExamListComponent },
      { path: 'exam/:id', component: ExamComponent }
    ]
  },
  { path: 'questions', component: QuestionsComponent },
  { path: 'candidate/:id', component: CandidateComponent },
  { path: 'candidate', component: CandidateComponent },

  { path: '**', component: NotFoundComponent }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: [
    CanDeactivateGuard
  ]
})
export class AppRoutingModule { }
