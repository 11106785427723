<h2>{{ 'admin.staff' | translate }}</h2>

<mat-form-field class="filter">
  <input matInput (keyup)="applyFilter($event.target['value'])"
    [placeholder]="'admin.candidatesComponent.search' | translate">
</mat-form-field>

<mat-card appearance="outlined">
  <table mat-table [dataSource]="dataSource" matSort id="users-table">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef id="user-id-header" mat-sort-header>
        {{ 'global.userID' | translate }}
      </th>
      <td mat-cell class="tableCell" *matCellDef="let element">{{element.id.substr(0,4)}}</td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="name-header" (click)="getUserData()">
        {{ 'global.name' | translate }}
      </th>
      <td mat-cell class="tableCell" *matCellDef="let element">{{element.name}}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="email-header">
        {{ 'global.email' | translate }}
      </th>
      <td mat-cell class="tableCell" *matCellDef="let element">{{element.email}}</td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="role-header">
        {{ 'global.role' | translate }}
      </th>
      <td mat-cell class="tableCell" *matCellDef="let element">{{ element.role | translate }}</td>
    </ng-container>

    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="location-header">
        {{ 'global.location' | translate }}
      </th>
      <td mat-cell class="tableCell" *matCellDef="let element">{{element.location}}</td>
    </ng-container>

    <ng-container matColumnDef="remove">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'global.remove' | translate }}
      </th>
      <td mat-cell class="tableCell" *matCellDef="let element"><button mat-raised-button
          (click)="removeUser(element.id)">X</button></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let myRowData; let element; columns: displayedColumns;"></tr>
  </table>

  <div *ngIf="dataSource.filteredData.length === 0" class="no-data">
    —
  </div>
</mat-card>
