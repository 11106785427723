import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TextService {

  constructor(private http: HttpClient) { }

  fetchText(textName, lang) {
    return this.http.get<any>('/api/texts/' + textName + '?lang=' + lang);
  }

  postText(textName, input, lang) {
    return this.http.post<any>(
      '/api/texts/' + textName + '?lang=' + lang, {content: input}
    );
  }

  previewEmail(textName, input, lang) {
    return this.http.post<any>(
      '/api/previewEmail/' + textName + '?lang=' + lang, {content: input}
    );
  }
}
