import { Component, OnInit, HostListener, ViewEncapsulation } from '@angular/core';
import { TextService } from 'src/app/_services/text.service';
import { DialogService } from 'src/app/_services/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import * as texts from './texts';
import { Text } from './texts';
import { Observable, of, Subject } from 'rxjs';
import { take, debounceTime, takeUntil } from 'rxjs/operators';
import { NotificationService } from '../../_services/notification.service';
import { DestroyComponent } from '../../util/destroy.component';

interface EmailPreview {
  text: Text;
  content: string;
}

@Component({
  selector: 'app-texts',
  templateUrl: './texts.component.html',
  styleUrls: ['./texts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TextsComponent extends DestroyComponent implements OnInit {


  content = '';
  preview = '';
  unsavedChanges = false;
  emailOk = true;
  emailError = '';

  texts: Text[];
  selectedText: Text;

  currTextLang: string;

  private couldNotSaveText: string;
  private authorizationErrorText: string;
  private textIsEmptyText: string;
  private notFoundText: string;
  private textHasBeenSavedText: string;
  private updateEmailPreviewSub: Subject<EmailPreview> = new Subject();

  constructor(private textService: TextService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private notificationService: NotificationService) {
    super();
    const webPages = texts.webPages.map(texts.initWebPageText);
    const emails = texts.emails.map(texts.initEmailText);
    this.texts = webPages.concat(emails);
    this.selectedText = this.texts[0];
    this.setupUpdateEmailPreviewSub();
    this.streamTranslations();
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.unsavedChanges) {
      $event.returnValue = true;
    }
  }
  ngOnInit() {
    this.currTextLang = this.translate.currentLang;
    this.reloadText();
  }

  streamTranslations() {
    this.translate.stream([
      'admin.texts.errors.couldNotSave',
      'admin.texts.errors.authorizationError',
      'admin.texts.errors.textIsEmpty',
      'admin.texts.errors.notFound',
      'admin.texts.textHasBeenSaved'
    ]).pipe(takeUntil(this.destroy)).subscribe(data => {
      this.couldNotSaveText = data['admin.texts.errors.couldNotSave'];
      this.authorizationErrorText = data['admin.texts.errors.authorizationError'];
      this.textIsEmptyText = data['admin.texts.errors.textIsEmpty'];
      this.notFoundText = data['admin.texts.errors.notFound'];
      this.textHasBeenSavedText = data['admin.texts.textHasBeenSaved'];
    }
    );
  }

  onTextSelect(event: any): void {
    const selectedText = event.value;
    if (this.unsavedChanges) {
      this.dialogService.confirm({
        titleLangKey: 'admin.texts.unsavedChangesDialog.title',
        bodyLangKey: 'admin.texts.unsavedChangesDialog.bodyChangeLang',
        confirmLangKey: 'admin.texts.unsavedChangesDialog.confirm',
        cancelLangKey: 'admin.texts.unsavedChangesDialog.cancel'
      }).afterClosed().pipe(take(1)).subscribe(confirm => {
        if (confirm) {
          this.selectedText = selectedText;
          this.reloadText();
        }
      });
    } else {
      this.selectedText = selectedText;
      this.reloadText();
    }
  }

  onLangButtonsClick(event: any, clickedLanguage: string): void {
    if (this.unsavedChanges) {
      event.preventDefault();
      this.dialogService.confirm({
        titleLangKey: 'admin.texts.unsavedChangesDialog.title',
        bodyLangKey: 'admin.texts.unsavedChangesDialog.bodyChangeLang',
        confirmLangKey: 'admin.texts.unsavedChangesDialog.confirm',
        cancelLangKey: 'admin.texts.unsavedChangesDialog.cancel'
      }).afterClosed().pipe(take(1)).subscribe(confirm => {
        if (confirm) {
          this.currTextLang = clickedLanguage;
          this.reloadText();
        }
      });
    }
  }

  reloadText(): void {
    this.content = '';
    this.preview = '';
    this.unsavedChanges = false;
    this.emailOk = true;
    this.emailError = '';

    this.load(this.selectedText);
  }

  load(text: Text) {
    this.textService.fetchText(text.file, this.currTextLang).subscribe(
      data => {
        this.content = data;
        if (this.isEmailText(text)) {
          this.updateEmailPreviewSub.next({ text, content: this.content });
        }
      }
    );
  }

  isEmailText(text: Text): boolean {
    return text.file.endsWith('.txt');
  }

  onTextInput(text: Text, input: string): void {
    this.setChangedFlag(text);
    if (this.isEmailText(text)) {
      this.updateEmailPreviewSub.next({ text, content: input });
    } else {
      this.content = input;
    }
  }

  setChangedFlag(text: Text): void {
    this.unsavedChanges = true;
  }

  save(text: Text, input: string) {
    this.textService.postText(text.file, input, this.currTextLang).subscribe(
      data => {
        this.load(text);
        this.notificationService.success(this.textHasBeenSavedText);
        this.unsavedChanges = false;
      },
      err => {
        const error = err.error.error;
        let message = this.couldNotSaveText + ': ';
        switch (error) {
          case 'badAuth':
            message += this.authorizationErrorText + '.';
            break;
          case 'emptyContent':
            message += this.textIsEmptyText + '.';
            break;
          case 'notFound':
            message += this.notFoundText + '.';
            break;
        }
        this.notificationService.error(message);
      }
    );
  }

  canDeactivate(): Observable<boolean> {
    if (!this.unsavedChanges) {
      return of(true);
    }
    return this.dialogService.confirm({
      titleLangKey: 'admin.texts.unsavedChangesDialog.title',
      bodyLangKey: 'admin.texts.unsavedChangesDialog.bodyLeave',
      confirmLangKey: 'admin.texts.unsavedChangesDialog.confirm',
      cancelLangKey: 'admin.texts.unsavedChangesDialog.cancel'
    }).afterClosed();
  }

  /*
   * Inserts the necessary HTML into the preview text to make it
   * highlighted. Assumes the highlight list is sorted by the start
   * of the intervals.
   */
  private createHighlightedText(text: string, highlight: [number, number, boolean][]): string {
    const textSplit = [];
    let startIndex = 0;

    for (const interval of highlight) {
      const [firstEnd, highlightEnd, isCorrect] = interval;
      const firstPart = this.escapeHtml(text.substring(startIndex, firstEnd));
      const highlightedWord = this.escapeHtml(text.substring(firstEnd, highlightEnd + 1));

      const cssClass = interval[2] ? 'correct-template' : 'error-template';
      const htmlStartTag = '<span class="' + cssClass + '">';
      const htmlEndTag = '</span>';
      textSplit.push(firstPart);
      textSplit.push(htmlStartTag);
      textSplit.push(highlightedWord);
      textSplit.push(htmlEndTag);
      startIndex = highlightEnd + 1;
    }
    const rest = this.escapeHtml(text.substring(startIndex));
    textSplit.push(rest);
    const result = textSplit.reduce((a, b) => a + b);
    return result.replace(/\n/g, '<br>'); // to make line endings work
  }

  private setupUpdateEmailPreviewSub(): void {
    this.updateEmailPreviewSub.pipe(debounceTime(200), takeUntil(this.destroy)).subscribe(update => {
      this.textService.previewEmail(update.text.file, update.content, this.currTextLang).subscribe(
        data => {
          const preview = data.preview;
          const highlight = data.highlight;
          const textOk = data.ok;
          const errorType = data.errorType;
          this.preview = this.createHighlightedText(preview, highlight);
          this.emailOk = textOk;
          if (!textOk) {
            switch (errorType) {
              case 'double_start_brace':
                this.emailError = 'admin.texts.errors.doubleStartBrace';
                break;
              case 'missing_end_brace':
                this.emailError = 'admin.texts.errors.missingEndBrace';
                break;
              case 'missing_start_brace':
                this.emailError = 'admin.texts.errors.missingStartBrace';
                break;
              default:
                this.emailError = 'admin.texts.errors.invalidKeys';
            }
          } else {
            this.emailError = '';
          }
        }
      );
    });
  }

  private escapeHtml(text: string): string {
    const replacements = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      '\'': '&#39;',
      '/': '&#x2F;'
    };

    return text.replace(/[&<>"'\/]/g, s => replacements[s]);
  }
}
