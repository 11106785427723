import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef } from '@angular/material/dialog';
import { UserDataService, Status, findStatus, STATUS_NAME_MAP, END_REASON_NAMES, Comments } from '../../_services/user-data.service';
import { UploadService } from '../../_services/upload.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../../_services/dialog.service';
import { take, takeUntil } from 'rxjs/operators';
import { NotificationService } from '../../_services/notification.service';
import { DestroyComponent } from '../../util/destroy.component';
import { cities } from 'src/app/common/city';

export interface StatusHistoryRow {
  timestamp: number;
  changed: string;
  status: string;
}

export interface JSONDate {
  year: number;
  month: number;
  day: number;
}

const errorKeys = [
  'candidate.errors.noCand',
  'candidate.errors.examEnd',
  'candidate.errors.badAuth',
  'candidate.errors.badAuthEnd',
  'candidate.errors.noFile',
  'candidate.errors.fileExtension'
];

@Component({
  selector: 'app-candidate-data',
  templateUrl: './candidate-data.component.html',
  styleUrls: ['./candidate-data.component.scss']
})
export class CandidateDataComponent extends DestroyComponent implements OnInit {
  id: string;

  candidateColumns: string[] = ['id', 'name', 'email', 'location', 'status', 'endReason'];
  examColumns: string[] = ['startTime', 'endTime', 'examTime', 'files', 'graderGroup', 'gradingDeadline'];

  userData: any;
  dataSource = new MatTableDataSource();

  status: Status = { name: '', niceName: '' };
  startTime;
  endTime;
  examTime;
  hours;
  minutes;
  seconds;

  statusHistoryData: StatusHistoryRow[] = [];

  dataSourceHistory = new MatTableDataSource(this.statusHistoryData);
  uploadForm: UntypedFormGroup;

  displayedColumnsHistory: string[] = ['changed', 'statusHistory', 'changedBy'];

  comments: Comments[];

  selectedGraderCity: string;

  processDone = false;
  disabled = true;
  file?: string;
  filename?: string;
  link: string;
  today = new Date();
  deadline: Date;
  alreadyAssigned = false;

  supportedExtensions: string[];

  cities = cities;

  private sub: any;

  private testAssignedText: string;
  private couldNotAssignText: string;
  private couldNotRemoveText: string;
  private unknownErrorText: string;
  private fileNotSupportedText: string;
  private groupAndDeadlineText: string;
  private clipboardSuccess: string;
  private clipboardFail: string;
  private couldNotHandInText: string;
  private errorMessages = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private userDataService: UserDataService,
    private uploadService: UploadService,
    private dialogService: DialogService,
    private notificationService: NotificationService
  ) {
    super();
    this.supportedExtensions = [];
    this.streamTranslations();
  }

  ngOnInit() {
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });

    this.uploadService.getSupportedExtensions().subscribe(
      data => {
        this.supportedExtensions = data;
      },
      err => {
        this.notificationService.error(this.unknownErrorText);
        console.error(err);
      }
    );

    this.sub = this.route.params.pipe(takeUntil(this.destroy)).subscribe(params => {
      this.id = params.id;
      this.getCandidateData(this.id);
      this.getStatusHistory(this.id);
      this.getComments();
    });
  }

  getCandidateData(id: string) {
    this.userDataService.candidateProfile(id).subscribe(
      data => {
        this.userData = data;
        this.status = findStatus(data.status);
        data.status = this.status.niceName;
        data.endReason = END_REASON_NAMES[data.endReason];
        this.dataSource.data = [data];
        this.processDone = this.status.name === 'DONE';
        this.link = window.location.origin + '/candidate/' + data.id;
        this.getExamTime(id);
        this.file = data.file;
        this.selectedGraderCity = data.graderGroup;
        this.alreadyAssigned = !!data.graderGroup;
        this.deadline = data.gradingDeadline ? new Date(data.gradingDeadline) : new Date();
        if (this.file) {
          this.filename = this.file.replace(/^.*[\\\/]/, '');
        }
      },
      err => { console.error('Fail: ', err); }
    );
  }

  streamTranslations() {
    this.translate.stream([
      'admin.candidateData.messages.testAssigned',
      'admin.candidateData.messages.couldNotAssign',
      'admin.candidateData.messages.couldNotRemove',
      'admin.candidateData.messages.fileNotSupported',
      'admin.candidateData.messages.groupAndDeadline',
      'global.unknownErrorOccurred',
      'candidate.couldNotHandIn',
      'admin.candidateData.messages.clipboardSuccess',
      'admin.candidateData.messages.clipboardFail',
      ...errorKeys
    ]).pipe(takeUntil(this.destroy)).subscribe(
      data => {
        this.testAssignedText = data['admin.candidateData.messages.testAssigned'];
        this.couldNotAssignText = data['admin.candidateData.messages.couldNotAssign'];
        this.couldNotRemoveText = data['admin.candidateData.messages.couldNotRemove'];
        this.fileNotSupportedText = data['admin.candidateData.messages.fileNotSupported'];
        this.groupAndDeadlineText = data['admin.candidateData.messages.groupAndDeadline'];
        this.unknownErrorText = data['global.unknownErrorOccurred'];
        this.couldNotHandInText = data['candidate.couldNotHandIn'];
        this.clipboardSuccess = data['admin.candidateData.messages.clipboardSuccess'];
        this.clipboardFail = data['admin.candidateData.messages.clipboardFail'];

        for (const key of errorKeys) {
          const keySplit = key.split('.');
          const shortKey = keySplit[keySplit.length - 1];
          this.errorMessages[shortKey] = data[key];
        }
      }
    );
  }

  getStatusHistory(id: string) {
    this.userDataService.statusHistory(id).subscribe(
      data => {
        this.statusHistoryData = data.map(item => ({
          changed: item.changed,
          timestamp: item.timestamp,
          changedBy: item.changedBy,
          status: STATUS_NAME_MAP[item.status]
        })).sort((lhs, rhs) => rhs.timestamp - lhs.timestamp);
        this.dataSourceHistory.data = this.statusHistoryData;
      },
      err => { console.error('Fail: ', err); }
    );
  }

  toggleDoneStatus() {
    this.userDataService.adminToggleStatus(this.id).subscribe(
      data => {
        this.status = findStatus(data.status);
        this.processDone = this.status.name === 'DONE';
        this.getCandidateData(this.id);
        this.getStatusHistory(this.id);
      },
      err => { console.error('Fail: ', err); }
    );
  }

  getExamTime(id: string) {
    this.userDataService.fetchStartEndTime(id).subscribe(
      data => {
        this.startTime = data[0];
        this.endTime = data[1];
        if (this.startTime && this.endTime) {
          this.examTime = (this.endTime - this.startTime);
          this.hours = Math.floor(this.examTime / 3600);
          this.minutes = Math.floor((this.examTime - (this.hours * 3600)) / 60);
          this.seconds = this.examTime - (this.hours * 3600) - (this.minutes * 60);
        }
      },
      err => { console.error('Fail: ', err); }
    );
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      if (this.isValidFile(file.name)) {
        this.uploadForm.get('profile').setValue(file);

        const formData = new FormData();
        formData.append('file', this.uploadForm.get('profile').value);

        this.uploadService.uploadAsAdmin(formData, this.id).subscribe(
          data => {
            this.getCandidateData(this.id);
          },
          err => {
            const errorCode = err.error.error;
            const msg = err.error.msg;

            this.notificationService.error(this.errorMessages[errorCode], this.couldNotHandInText);
            if (msg) {
              console.error(msg);
            }
          }
        );
      } else {
        console.log(file);
        this.notificationService.error(this.fileNotSupportedText);
      }
    }
  }

  selectGraderGroup() {
    if (this.selectedGraderCity && this.deadline) {
      const jsonDate: JSONDate = { year: this.deadline.getFullYear(), month: this.deadline.getMonth() + 1, day: this.deadline.getDate() };
      this.userDataService.updateGraderGroup(this.id, this.selectedGraderCity, jsonDate).subscribe(
        data => {
          this.getCandidateData(this.id);
          this.getStatusHistory(this.id);
          this.notificationService.success(this.testAssignedText);
        },
        error => {
          this.notificationService.error(this.couldNotAssignText);
          console.error(error);
        }
      );
    } else {
      this.notificationService.error(this.groupAndDeadlineText);
    }
  }

  createAcceptedExtensionsForUpload(): string {
    return this.supportedExtensions.map(ext => '.' + ext).join(',');
  }

  isValidFile(fileName: string): boolean {
    return this.supportedExtensions.some(extension => fileName.endsWith(extension));
  }

  getComments() {
    this.userDataService.fetchComments(this.id).subscribe(
      data => {
        this.comments = data;
      }
    );
  }

  deleteCandidate() {
    this.openDeleteDialog().afterClosed().pipe(take(1)).subscribe(confirm => {
      if (confirm) {
        this.userDataService.deleteCandidate(this.id).subscribe(
          data => {
            this.router.navigate(['/admin/candidates']);
          },
          err => {
            this.notificationService.error(this.couldNotRemoveText);
            console.error(err);
          }
        );
      }
    });
  }

  onClipboardCopy(success: boolean): void {
    if (success) {
      this.notificationService.success(this.clipboardSuccess);
    } else {
      this.notificationService.error(this.clipboardFail);
    }
  }

  private openDeleteDialog(): MatDialogRef<any, boolean> {
    return this.dialogService.confirm({
      titleLangKey: 'admin.candidatesComponent.confirmRemoveDialog.single.title',
      bodyLangKey: 'admin.candidatesComponent.confirmRemoveDialog.single.body',
      confirmLangKey: 'admin.candidatesComponent.confirmRemoveDialog.single.confirm',
      cancelLangKey: 'admin.candidatesComponent.confirmRemoveDialog.single.cancel',
      params: { candidate: this.getCandidateName() }
    });
  }

  private getCandidateName(): string {
    return `"${this.userData.name}"`;
  }
}
