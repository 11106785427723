import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogData } from '../dialog/confirm/confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private matDialog: MatDialog) { }

  confirm(data: ConfirmDialogData): MatDialogRef<any, boolean> {
    return this.matDialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data
    });
  }
}
