export interface Text {
  name: string;
  file: string;
  title: string;
  description: string;
  templateDescription?: string;
}

function toCamelCase(s: string) {
    return s.replace(/(_[a-z])/ig, ($1) => $1.toUpperCase()
            .replace('_', ''));
}

export function initWebPageText(name: string): Text {
  const camelName = toCamelCase(name);
  return {
    name,
    title: 'admin.texts.html.' + camelName + 'Title',
    file: name + '.html',
    description: 'admin.texts.html.' + camelName + 'Description',
  };
}

export const webPages: string[] = [
  'welcome',
  'done',
  'timeout',
  'outdated_link',
  'questions',
];

export function initEmailText(name: string): Text {
  console.assert(name.endsWith('_email'), name + ' does not end with _email');
  const camelName = toCamelCase(name.replace('_email', ''));
  return {
    name,
    title: 'admin.texts.email.' + camelName + 'Title',
    file: name + '.txt',
    description: 'admin.texts.email.' + camelName + 'Title',
    templateDescription: 'admin.texts.email.' + camelName + 'TemplateDescription',
  };
}

export const emails: string[] = [
  'link_email',
  'upload_confirmed_email',
  'upload_notification_email',
  'grader_notification_email',
  'grader_deadline_email',
  'user_created_notification_email',
  'grader_commented_email',
  'candidate_exam_closed_email',
  'link_soon_outdated_email',
  'admin_exam_closed_email',
];
