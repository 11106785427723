import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MaterialModule } from './material';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { AppComponent } from './app.component';
import { AdminComponent } from './admin-component/admin.component';
import { AddUserComponent } from './admin-component/add-user/add-user.component';
import { CandidateDataComponent } from './admin-component/candidate-data/candidate-data.component';
import { CandidateComponent } from './candidate-component/candidate.component';
import { LoginComponent } from './login-component/login.component';
import { QuestionsComponent } from './questions-component/questions.component';
import { ClockComponent } from './questions-component/clock-component/clock.component';
import { NotFoundComponent } from './notfound-component/notfound.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { GraderComponent } from './grader-component/grader.component';
import { ExamComponent } from './grader-component/exam-component/exam.component';
import { ExamListComponent } from './grader-component/exam-list/exam-list.component';
import { TextsComponent } from './admin-component/texts/texts.component';
import { UsersComponent } from './admin-component/users/users.component';
import { CandidatesComponent } from './admin-component/candidates/candidates.component';
import { ToastrModule } from 'ngx-toastr';
import { ClipboardModule } from 'ngx-clipboard';
import { CandidatesTableComponent } from './admin-component/candidates/candidates-table.component';

import { RequestInterceptor } from './_services/request-interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from './auth.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AddUserComponent,
    CandidateDataComponent,
    CandidateComponent,
    LoginComponent,
    ClockComponent,
    QuestionsComponent,
    GraderComponent,
    ExamComponent,
    ExamListComponent,
    TextsComponent,
    UsersComponent,
    NotFoundComponent,
    CandidatesComponent,
    CandidatesTableComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CdkTableModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    ClipboardModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    AuthModule,
  ],
  exports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    CdkTableModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AuthModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    CookieService,
    { provide: MAT_DATE_LOCALE, useValue: 'sv-SE' },
  ],
  bootstrap: [AppComponent]
})


export class AppModule { }
