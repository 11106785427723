<mat-sidenav-container>
  <mat-sidenav mode="side"
               opened>
    <app-clock-component></app-clock-component>
    <form [formGroup]="uploadForm"
          (ngSubmit)="uploadFunc()">
      <span class="file-extensions">
        <i>{{'candidate.exam.allowedFileTypes' | translate}}:</i>
        {{createAcceptedExtensionsText()}}
      </span>
      <input type="file"
             id="file"
             name="file"
             (change)="onFileSelect($event)"
             class="hiddeninput"
             accept="{{createAcceptedExtensionsForUpload()}}"/>
      <label for="file">
          <div class="fake-button">
            {{'candidate.exam.selectAFile' | translate}}
          </div>
      </label>

      <br />
      <div [hidden]="!hasFile"
           class="file">
        {{'candidate.exam.selectedFile' | translate}}: {{currentFileName()}}
      </div>
      <div [hidden]="!hasFile">
        <button
                type="submit"
                [disabled]="uploadStarted">
          {{'candidate.exam.handIn' | translate}}
        </button>
      </div>
      <br />
      <div>
        <span class="upload-text">
          {{uploadText | translate:translateParam}}
        </span>
      </div>
    </form>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-card-content>
      <div id="questions-container"
           [innerHTML]="questionHtml"></div>
    </mat-card-content>
  </mat-sidenav-content>
</mat-sidenav-container>
