import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { UserDataService, ROLES } from '../../_services/user-data.service';
import { Router } from '@angular/router';
import { LoginService } from '../../_services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../../_services/dialog.service';
import { take, takeUntil } from 'rxjs/operators';
import { NotificationService } from '../../_services/notification.service';
import { DestroyComponent } from '../../util/destroy.component';

export interface UserRow {
  id: string;
  name: string;
  email: string;
  role: string;
  location: string;
}

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent extends DestroyComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  userData: UserRow[] = [];
  dataSource = new MatTableDataSource(this.userData);
  displayedColumns: string[] = ['id', 'name', 'email', 'role', 'location', 'remove'];

  private couldNotRemoveUserText: string;

  constructor(public router: Router,
    private userDataService: UserDataService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private notificationService: NotificationService) {
    super();
    this.streamTranslations();
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.getUserData();
  }

  getUserData() {
    this.userDataService.userData().subscribe(
      data => {
        for (const row of data) {
          row.role = ROLES.find(p => p.name === row.role).niceName;
        }
        this.userData = data;
        this.dataSource.data = data;
      },
      err => { console.error('Could not fetch user data', err); });
  }

  streamTranslations() {
    this.translate.stream([
      'admin.users.couldNotRemoveUser',
    ]).pipe(takeUntil(this.destroy)).subscribe(
      data => {
        this.couldNotRemoveUserText = data['admin.users.couldNotRemoveUser'];
      }
    );
  }

  removeUser(id: string) {
    this.openRemoveDialog(id).afterClosed().pipe(take(1)).subscribe(confirm => {
      if (confirm) {
        this.userDataService.deleteUser(id).subscribe(
          data => {
            this.dataSource.sort = this.sort;
            this.getUserData();
          },
          err => {
            console.error(err);
            this.notificationService.error(this.couldNotRemoveUserText);
          }
        );
      }
    });
  }

  applyFilter(filter: string) {
    this.dataSource.filter = filter.trim().toLowerCase();
  }

  private openRemoveDialog(id: string): MatDialogRef<any, boolean> {
    return this.dialogService.confirm({
      titleLangKey: 'admin.users.confirmRemoveDialog.title',
      bodyLangKey: 'admin.users.confirmRemoveDialog.body',
      confirmLangKey: 'admin.users.confirmRemoveDialog.confirm',
      cancelLangKey: 'admin.users.confirmRemoveDialog.cancel',
      params: { user: this.getUserName(id) }
    });
  }

  private getUserName(id: string): string {
    return `"${this.userData.find(u => u.id === id).name}"`;
  }
}
