import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { HttpEventType } from '@angular/common/http';
import { UploadService } from '../_services/upload.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../_services/dialog.service';
import { MatDialogRef } from '@angular/material/dialog';
import { take, takeUntil } from 'rxjs/operators';
import { NotificationService } from '../_services/notification.service';
import { DestroyComponent } from '../util/destroy.component';

const errorKeys = [
  'candidate.errors.noCand',
  'candidate.errors.examEnd',
  'candidate.errors.badAuth',
  'candidate.errors.badAuthEnd',
  'candidate.errors.noFile',
  'candidate.errors.fileExtension',
  'candidate.errors.upload'
];

@Component({
  selector: 'app-upload',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
  providers: [CookieService]
})

export class QuestionsComponent extends DestroyComponent implements OnInit {

  hasFile: boolean;
  uploadForm: UntypedFormGroup;
  supportedExtensions: string[];
  questionHtml: string;
  uploadStarted = false;

  uploadText = '';
  translateParam = { percentage: 0 };

  private unknownErrorText: string;
  private fileNotSupportedText: string;
  private examHandedInText: string;
  private couldNotGetExtensionsText: string;
  private somethingWentWrongText: string;
  private tryUsingLinkAgainText: string;
  private questionsNotFoundText: string;
  private badAuthorizationText: string;
  private couldNotHandInText: string;
  private errorMessages = {};

  constructor(
    private formBuilder: UntypedFormBuilder,
    private uploadService: UploadService,
    private translate: TranslateService,
    private router: Router,
    private dialogService: DialogService,
    private notificationService: NotificationService) {
    super();
    this.hasFile = false;
    this.supportedExtensions = [];
    this.streamTranslations();
  }

  ngOnInit() {
    this.translate.onLangChange.pipe(takeUntil(this.destroy)).subscribe(() => this.loadQuestions());
    this.loadQuestions();

    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });

    this.uploadService.getSupportedExtensions().subscribe(
      data => {
        this.supportedExtensions = data;
      },
      err => {
        console.error(err);
        this.notificationService.error(this.couldNotGetExtensionsText);
      }
    );
  }

  loadQuestions() {
    this.uploadService.fetchQuestions(this.translate.currentLang).subscribe(
      data => {
        this.questionHtml = data;
      },
      err => {
        console.error(err);
        this.questionHtml = '<h1>' + this.somethingWentWrongText + '</h1>' +
          this.tryUsingLinkAgainText;
        switch (err.error.error) {
          case 'badAuth':
            this.notificationService.error(this.badAuthorizationText);
            break;
          case 'textNotFound':
            this.notificationService.error(this.questionsNotFoundText);
            break;
          default:
            this.notificationService.error(this.unknownErrorText + ': ' + err.error.error);
        }
      }
    );
  }

  streamTranslations() {
    this.translate.stream([
      'global.unknownErrorOccurred',
      'candidate.exam.fileNotSupported',
      'candidate.exam.examHandedIn',
      'candidate.exam.couldNotFetchAllowedExtensions',
      'candidate.exam.somethingWentWrong',
      'candidate.exam.tryUsingLinkAgain',
      'candidate.exam.questionsNotFound',
      'candidate.exam.badAuthorization',
      'candidate.couldNotHandIn',
      ...errorKeys
    ]).pipe(takeUntil(this.destroy)).subscribe(
      data => {
        this.unknownErrorText = data['global.unknownErrorOccurred'];
        this.fileNotSupportedText = data['candidate.exam.fileNotSupported'];
        this.examHandedInText = data['candidate.exam.examHandedIn'];
        this.couldNotGetExtensionsText = data['candidate.exam.couldNotFetchAllowedExtensions'];
        this.somethingWentWrongText = data['candidate.exam.somethingWentWrong'];
        this.tryUsingLinkAgainText = data['candidate.exam.tryUsingLinkAgain'];
        this.questionsNotFoundText = data['candidate.exam.questionsNotFound'];
        this.badAuthorizationText = data['candidate.exam.badAuthorization'];
        this.couldNotHandInText = data['candidate.couldNotHandIn'];

        for (const key of errorKeys) {
          const keySplit = key.split('.');
          const shortKey = keySplit[keySplit.length - 1];
          this.errorMessages[shortKey] = data[key];
        }
      }
    );
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (this.isValidFile(file.name)) {
        this.uploadForm.get('profile').setValue(file);
        this.hasFile = true;
      } else {
        this.notificationService.error(this.fileNotSupportedText);
      }
    }
  }

  uploadFunc() {
    if (!this.hasFile) {
      return;
    }

    this.openConfirmDialog().afterClosed().pipe(take(1)).subscribe(confirm => {
      if (confirm) {
        const formData = new FormData();
        formData.append('file', this.uploadForm.get('profile').value);
        this.uploadStarted = true;

        this.uploadService.upload(formData).subscribe(
          event => {
            switch (event.type) {
              case HttpEventType.Sent:
                this.translateParam.percentage = 0;
                this.uploadText = 'candidate.exam.uploadStarted';
                break;
              case HttpEventType.UploadProgress:
                this.translateParam.percentage = Math.round(100 * event.loaded / event.total);
                this.uploadText = 'candidate.exam.uploading';
                break;
              case HttpEventType.Response:
                this.notificationService.success(this.examHandedInText);
                this.router.navigateByUrl('/candidate');
                break;
            }
          },
          err => {
            const errorCode = err.error.error;
            const msg = err.error.msg;
            this.uploadStarted = false;

            this.notificationService.error(this.errorMessages[errorCode], this.couldNotHandInText);
            if (msg) {
              console.error(msg);
            }

            if (errorCode === 'examEnd') {
              this.router.navigate(['/candidate']);
            }
          }
        );
      }
    });
  }

  createAcceptedExtensionsText(): string {
    return this.supportedExtensions.join(', ');
  }

  createAcceptedExtensionsForUpload(): string {
    return this.supportedExtensions.map(ext => '.' + ext).join(',');
  }

  currentFileName(): string {
    return this.uploadForm.get('profile').value.name;
  }

  private isValidFile(fileName: string): boolean {
    return this.supportedExtensions.some(extension => fileName.endsWith(extension));
  }

  private openConfirmDialog(): MatDialogRef<any, boolean> {
    return this.dialogService.confirm({
      titleLangKey: 'candidate.exam.confirmDialog.title',
      bodyLangKey: 'candidate.exam.confirmDialog.body',
      cancelLangKey: 'candidate.exam.confirmDialog.cancel',
      confirmLangKey: 'candidate.exam.confirmDialog.cofirm'
    });
  }
}
