import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserDataService, findStatus, STATUS_NAME_MAP, END_REASON_NAMES, Comments, Status } from '../../_services/user-data.service';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '../../_services/dialog.service';
import { take, takeUntil } from 'rxjs/operators';
import { NotificationService } from '../../_services/notification.service';
import { DestroyComponent } from '../../util/destroy.component';


export interface StatusTable {
  timestamp: any;
  changed: any;
  status: any;
}

export interface CandidateTable {
  id: any;
  endtime: string;
  deadline: string;
  status: string;
}

@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.scss']
})
export class ExamComponent extends DestroyComponent implements OnInit, AfterViewInit {
  statusTable: StatusTable[];
  candidateData: CandidateTable[];

  displayedColumns: string[] = ['status', 'changed'];

  displayedColumnsForCandidate: string[] = ['id', 'endtime', 'deadline', 'status', 'endReason'];

  candidateUuid: string;
  processDone = false;

  commentValue = new UntypedFormControl();
  path: string;
  status: Status;

  myId: string;

  comments: Comments[];

  private commentHasBeenAddedText: string;
  private couldNotAddCommentText: string;
  private noCommentsYetText: string;
  private couldNotRemoveCommentText: string;

  constructor(private userDataService: UserDataService,
    private activeRoute: ActivatedRoute,
    private translate: TranslateService,
    private dialogService: DialogService,
    private notificationService: NotificationService) {
    super();
    this.streamTranslations();
  }

  ngOnInit() {
    this.userDataService.myId().subscribe(
      id => this.myId = id
    );
  }

  streamTranslations() {
    this.translate.stream([
      'grader.messages.commentHasBeenAdded',
      'grader.messages.couldNotAddComment',
      'grader.messages.noCommentsYet',
      'grader.messages.couldNotRemoveComment',
    ]).pipe(takeUntil(this.destroy)).subscribe(
      data => {
        this.commentHasBeenAddedText = data['grader.messages.commentHasBeenAdded'];
        this.couldNotAddCommentText = data['grader.messages.couldNotAddComment'];
        this.noCommentsYetText = data['grader.messages.noCommentsYet'];
        this.couldNotRemoveCommentText = data['grader.messages.couldNotRemoveComment'];
      }
    );
  }

  ngAfterViewInit() {
    this.activeRoute.params.pipe(takeUntil(this.destroy)).subscribe(
      params => {
        this.candidateUuid = params.id;
        this.testStatusHistory(this.candidateUuid);
        this.testGetCandidateDetails(this.candidateUuid);
      }
    );
  }

  toggleDoneStatus() {
    this.userDataService.graderToggleStatus(this.candidateUuid).subscribe(
      data => {
        this.processDone = (data.status === 'GRADED' || data.status === 'DONE');
        this.testGetCandidateDetails(this.candidateUuid);
        this.testStatusHistory(this.candidateUuid);
      },
      err => { console.error('Fail: ', err); }
    );
  }

  testStatusHistory(id: string) {
    return this.userDataService.statusHistory(id).subscribe(
      data => {
        this.statusTable = data;
        for (const i of Object.keys(data)) {
          data[i].status = STATUS_NAME_MAP[data[i].status];
        }
        data = data.sort((lhs, rhs) => rhs.timestamp - lhs.timestamp);
        this.statusTable = data;
      }
    );
  }

  testGetCandidateDetails(id: string) {
    this.userDataService.candidateProfile(id).subscribe(
      data => {
        this.status = findStatus(data.status);
        data.status = STATUS_NAME_MAP[data.status];
        data.endReason = END_REASON_NAMES[data.endReason];
        this.candidateData = [data];
        this.processDone = this.status.name === 'GRADED' || this.status.name === 'DONE';
      }
    );
    this.userDataService.getTestFiles(this.candidateUuid).subscribe(
      data => {
        if (data.path) {
          this.path = data.path;
        }
      }
    );
  }

  submitComment() {
    if (this.commentValue.value) {

      this.userDataService.addExamComment(this.candidateUuid, this.commentValue.value).subscribe(
        data => {
          if (data.msg === 'Comment added') {
            this.commentValue.reset();
            this.getComments();
            this.notificationService.success(this.commentHasBeenAddedText);
          }
        },
        error => {
          this.notificationService.error(this.couldNotAddCommentText);
          console.error(error);
        }
      );
    }
  }

  getComments() {
    this.userDataService.fetchComments(this.candidateUuid).subscribe(
      data => {
        if (data && data.length) {
          this.comments = data;
        } else {
          this.notificationService.info(this.noCommentsYetText);
        }
      }
    );
  }

  isMyComment(comment: Comments): boolean {
    return comment.grader_id === this.myId;
  }

  removeComment(comment: Comments) {
    this.openRemoveCommentDialog(comment).afterClosed().pipe(take(1)).subscribe(confirm => {
      if (confirm) {
        this.userDataService.deleteComment(comment.comment_id).subscribe(
          data => {
            this.comments.splice(this.comments.indexOf(comment), 1);
          },
          error => {
            this.notificationService.error(this.couldNotRemoveCommentText);
            console.log(error);
          }
        );
      }
    });
  }

  private openRemoveCommentDialog(comment: Comments): MatDialogRef<any, boolean> {
    return this.dialogService.confirm({
      titleLangKey: 'grader.removeCommentDialog.title',
      bodyLangKey: 'grader.removeCommentDialog.body',
      confirmLangKey: 'grader.removeCommentDialog.confirm',
      cancelLangKey: 'grader.removeCommentDialog.cancel',
      params: { partOfComment: this.getPartOfComment(comment) }
    });
  }

  private getPartOfComment(comment: Comments): string {
    return `"${comment.comment.split(' ').slice(0, 4).join(' ')}..."`;
  }
}
