import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JSONDate } from '../admin-component/candidate-data/candidate-data.component';

export interface Status {
  name: string;
  niceName: string;
}

export interface Comments {
  comment: string;
  grader: string;
  comment_id: number;
  grader_id: string;
}

export const STATUS_NAME_MAP = {
  CREATED: 'global.statusName.created',
  HASVISITED: 'global.statusName.hasVisited',
  DOINGEXAM: 'global.statusName.doingExam',
  EXAMDONE: 'global.statusName.examDone',
  EXAMDISTRIBUTED: 'global.statusName.examDistributed',
  GRADED: 'global.statusName.graded',
  DONE: 'global.statusName.done'
};

export const END_REASON_NAMES = {
  FINISHED: 'global.endReasonName.finished',
  TIMEOUT: 'global.endReasonName.timeout',
  LINK_OUTDATED: 'global.endReasonName.linkOutdated',
};

export function findStatus(name: string): Status {
  return {
    name,
    niceName: STATUS_NAME_MAP[name]
  };
}

export interface Role {
  name: string;
  niceName: string;
}

export const ROLES: Role[] = [
  {name: 'candidate', niceName: 'global.candidate'},
  {name: 'admin', niceName: 'global.admin'},
  {name: 'grader', niceName: 'global.grader'},
];

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  constructor(private http: HttpClient) { }

  userData() {
    return this.http.get<any>('/api/users', { });
  }

  myId(): Observable<string> {
    return this.http.get('/api/users/me', { responseType: 'text' });
  }

  candidateData() {
    return this.http.get<any>('/api/candidates', { });
  }

  candidateProfile(id: string) {
    return this.http.get<any>('/api/candidates/' + id, {});
  }

  statusHistory(id: string) {
    return this.http.get<any>('/api/candidates/' + id + '/history' );
  }

  examTimesUp() {
    return this.http.post<any>('/api/candidate/prune', {});
  }

  adminToggleStatus(id: string) {
    return this.http.post<any>('/api/candidates/' + id + '/done', { });
  }

  graderToggleStatus(id: string) {
    return this.http.post<any>('/api/candidates/' + id + '/graded', { });
  }

  addUser(email: string, name: string, location: string, role: string) {
    return this.http.post<any>('/api/addUser', {email, name, location, role});
  }

  getTestFiles(id) {
    return this.http.get<any>('/api/candidates/' + id + '/file');
  }

  addExamComment(id, comment) {
    return this.http.post<any>('/api/candidates/' + id + '/comments', {comment});
  }

  fetchStartEndTime(id: string) {
      return this.http.get<any>('/api/candidates/' + id + '/time', { });
  }

  fetchStartTime() {
    return this.http.get<any>('/api/candidate/startT', { });
  }

  fetchRemainingTime() {
    return this.http.get<any>('/api/candidate/remainingTime', { });
  }

  updateGraderGroup(id, graderGroup, jsonDate: JSONDate) {
      return this.http.post<any>('/api/candidates/' + id + '/distributeExam', {group: graderGroup, date: jsonDate});
  }

  fetchComments(id) {
      return this.http.get<any>('/api/candidates/' + id + '/comments');
  }

  deleteUser(id) {
    return this.http.post<any>('/api/users/' + id + '/delete', {});
  }

  deleteCandidate(id) {
    return this.http.post<any>('/api/candidates/' + id + '/delete', {});
  }

  deleteMultipleCandidates(ids) {
    return this.http.post<any>('/api/candidates/delete', {candidates: ids});
  }

  deleteComment(id: number) {
    return this.http.delete('/api/comments/' + id);
  }
}
