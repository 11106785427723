import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserDataService } from '../../_services/user-data.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { DialogService } from '../../_services/dialog.service';
import { take } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-clock-component',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss'],
  providers: [CookieService]
})

export class ClockComponent implements OnInit, OnDestroy {
  interval: any;

  secondsRemaining: number;
  hoursRemaining = 2;
  minutesRemaining = 0;

  constructor(private userDataService: UserDataService,
    private router: Router,
    private dialogService: DialogService) {
    this.getRemainingTime();

    this.interval = setInterval(() => {
      this.getRemainingTime();
    }, 30000); // update every 30 seconds
  }

  getRemainingTime() {
    this.userDataService.fetchRemainingTime().subscribe(
      timedata => {
        if ('remainingTime' in timedata) {
          const [h, m, s] = this.getHoursMinutesAndSeconds(timedata.remainingTime);
          this.hoursRemaining = h;
          this.minutesRemaining = m;
          this.secondsRemaining = s;
        } else {
          this.hoursRemaining = 0;
          this.minutesRemaining = 0;
          this.secondsRemaining = 0;

          if (timedata.bufferTime === 0) {
            clearInterval(this.interval);
            this.openTimeIsUpDialog().afterClosed().pipe(take(1)).subscribe(() => {
              this.router.navigate(['/candidate']);
            });
          }
        }
      },
      err => {
        console.error('Fail: ', err);
      }
    );
  }

  ngOnInit() {
    this.getRemainingTime();
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  private getHoursMinutesAndSeconds(totalSeconds: number): [number, number, number] {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
    const seconds = totalSeconds - hours * 3600 - minutes * 60;
    return [hours, minutes, seconds];
  }

  private openTimeIsUpDialog(): MatDialogRef<any, boolean> {
    return this.dialogService.confirm({
      bodyLangKey: 'candidate.exam.timeIsUpDialog.body',
      confirmLangKey: 'candidate.exam.timeIsUpDialog.confirm',
      titleLangKey: 'candidate.exam.timeIsUpDialog.title'
    });
  }
}
